// Scritps Generales principal

//Images ORDENADOR
import FondoDivTextil from "../media/PC/bgHilosHomePC.jpg";
import BgHilos from "../media/PC/bgHilosHomePC.jpg";
import LogoDisex from "../media/PC/LOGO_DISEX2.png";
import MaqTej from "../media/PC/bgContacto.jpg";
import Disexbg from "../media/PC/Disexbg.jpg";
import DisenoPC from "../media/PC/DiseñoPC.jpg";
import MaquinaTela from "../media/PC/MaquinaTela.jpg";
import MaqTej2 from "../media/PC/bgMaquinaTejHomePC.jpg";
import BgPaqC from "../media/PC/bgHomePaqCompletoPC.jpg";

// IMAGENES CEL

// Miniaturas Telas
import TelaVancouver from "../media/PC/TelaVancouverPcMin.jpg";
import TelaRoja from "../media/PC/TelaRojaPcMin.jpg";
import RIBS from "../media/PC/RIB-Min.jpg";
import PoliAlgodon from "../media/PC/POLIESTER-ALGODON-Min.jpg";
import PoliNylon from "../media/PC/POLIESTER-NYLON-Min.jpg";
// import TelaFucsia from "../media/PC/TelaFucsiaPcMin.jpg";
import TelaRayas from "../media/PC/TelaRayasPcMin.jpg";
// import TelaVerde from "../media/PC/TelaVerdePc.jpg";

import TelaRosa from "../media/PC/TelaRosaPc.jpg";
import TelaRosa2 from "../media/PC/DSC02460.jpg";
import TelaMorada from "../media/PC/DSC02669.jpg";

//Miniaturas Imgs
import bgRopero from "../media/PC/RoperoBgMin.jpg";
import MaqTejMin from "../media/PC/MaqTejMin.jpg";
import MaqTejMin2 from "../media/PC/MaqTejMin2.jpg";
import HilosMin from "../media/PC/HilosMin.jpg";
import TelasMin from "../media/PC/TelasMin.jpg";
import CorteTelaMin from "../media/PC/CorteTelaMin.jpg";

//Icons
import InnovateIcon from "../icons/InnovateIcon.svg";
import CallIcon from "../icons/CallIcon.svg";
import CorreoIcon from "../icons/CorreoIcon.svg";
import NatureIcon from "../icons/NatureIcon.svg";
import LabIcon from "../icons/LabIcon.svg";
import AcabadosIcon from "../icons/AcabadosIcon.svg";
import PatronajeIcon from "../icons/PatronajeIcon.svg";
import TeamIcon from "../icons/TeamIcon.svg";
import LinkedinIcon from "../icons/LinkedinIcon.svg";
import InstagramIcon from "../icons/InstagramIcon.svg";
import LinkedinIconMin from "../icons/LinkedinIconMin.svg";
import InstagramIconMin from "../icons/InstagramIconMin.svg";
import LocationIcon from "../icons/LocationPin.svg";
import FollowIcon from "../icons/FollowIcon.svg";
import ContactIcon from "../icons/ContactIcon.svg";
import DevelopmentIcon from "../icons/DevelopmentIcon.svg";
import ProcessIcon from "../icons/ProcessIcon.svg";
import QualityIcon from "../icons/QualityIcon.svg";
import TruckIcon from "../icons/TruckIcon.svg";

//SVG grandes
import Mantenimiento from "../icons/Mantenimiento.svg";

//Contenidos para el HOME
const ContHomeHeader = {
  Banner: TelaRosa2,
  Texto: "Tejemos sueños, cosemos excelencia.",
  Subtitulo:
    "En Disex te ayudamos y respaldamos en todos tus procesos creativos y productivos",
  TextButton1: "Paquete Completo",
  TextButton2: "División Textil",
  Button1Classname: "button-transparent-right",
  Button2Classname: "button-transparent-left",
  Navigate1: "/PaqueteCompleto",
  Navigate2: "/DivisionTextil",
};

const ContHomeSection1 = {
  Bg:Disexbg,
  Tittle: "Somos DISEX",
  Parrafo:
    "Somos DISEÑOS EXCLUSIVOS SAS, y por más de 45 años hemos ayudado a marcas de moda a desarrollar y hacer realidad sus colecciones.",
  Button1: {
    text: "Conócenos",
    navigateto: "/Nosotros",
    classname: "button-transparent-section1Home",
  },
};

const ContHomeSection2 = {
  BgDivT: TelaMorada,
  BgPaqC: BgPaqC,
};

const ContHomeSection3 = {
  Tittle: "¿Qué nos hace diferentes?",
  Parrafo: "Entendemos y nos adaptamos al ADN de cada cliente",
  Bg: MaqTej2,
  ContInfoCards: {
    Card1: {
      contentFront: "Integración vertical",
      contentBack:
        "Capacidad productiva propia para prestar un servicio completo que comprende desde la creación de textiles en tejido de punto hasta el producto final.",
      icon: InnovateIcon,
    },
    Card2: {
      contentFront: "Respaldo profesional",
      contentBack:
        "Contamos con un equipo multidisciplinario y experto que entiende el ADN propio de cada marca que acompaña y respalda en cada momento creativo y productivo.",
      icon: TeamIcon,
    },
    Card3: {
      contentFront: "Acabados",
      contentBack:
        "Realizamos acabados diferenciadores en telas y prendas, que  le dan un agregado extra y singularizacion a tods tus diseños.",
      icon: AcabadosIcon,
    },
    Card4: {
      contentFront: "DISEX ECO",
      contentBack:
        "Textiles de algodón orgánico, poliéster y PET reciclado con certificaciones OEKO-TEX y GRS ahorran por tonelada: 7,764 m³ de agua, 28 MWh de energía, una tonelada de químicos, 11 toneladas de CO₂ y 19,740 botellas plásticas",
      icon: NatureIcon,
    },
    Card5: {
      contentFront: "Especializados en diseño y patronaje",
      contentBack:
        "Décadas de experiencia creando diseños y patrones impecables.",
      icon: PatronajeIcon,
    },
    Card6: {
      contentFront: "Laboatorio de color",
      contentBack:
        "Desarrollamos el color según tu pantone elegido y lo hacemos realidad en las bases textiles y prendas. ",
      icon: LabIcon,
    },
  },
};

const ContHomeSection4 = {
  Fondo: BgHilos,
};

//----------------------------CONTENIDO PARA LA DIVISION TEXTIL------------------

const bgDivTextInfoContact = {
  Fondo: TelaRoja,
};

const IconForms = {
  Icon1: CallIcon,
  Icon2: CorreoIcon,
  Icon3: LocationIcon,
};

const HeaderDivTextil = {
  Banner: FondoDivTextil,
  Texto: "División Textil",
  Subtitulo:
    "En Disex contamos con un amplio portafolio de telas en tejido de punto diferenciadas en sus texturas, materiales y acabados funcionales con altos estándares de investigación, diseño e innovación.",
  // Descripcion:
  //   "En Disex contamos con un amplio portafolio de telas en tejido de punto diferenciadas en sus texturas, materiales y acabados funcionales con altos estándares de investigación, diseño e innovación.",
  TextButton1: "Contáctanos",
  Navigate1: "/Contacto",
  Button1Classname: "button-transparent-general-center",
  Button2Classname: "button-null",
};

const section1DivTextil = {
  Card1: {
    TelaBg: TelaVancouver,
    NombreTela: "BURDAS",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
  Card2: {
    TelaBg: PoliNylon,
    NombreTela: "POLIÉSTER/NYLON",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
  Card3: {
    TelaBg: TelaRosa,
    NombreTela: "ALGODÓN",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
  Card4: {
    TelaBg: RIBS,
    NombreTela: "RIB",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
  Card5: {
    TelaBg: TelaRayas,
    NombreTela: "VISCOSA",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
  Card6: {
    TelaBg: PoliAlgodon,
    NombreTela: "POLIESTER ALGODÓN",
    // Propiedades: {
    //   Composicion1: "Algodón RECICLADO 50%",
    //   Composicion2: "Algodón ORGÁNICO 50%",
    //   Ancho: "1.50 Mts",
    //   Peso: "150 grs/ml",
    //   Rendimiento: "4.4",
    // },
  },
};

const section2DivTextil = {
  Img: PoliAlgodon,
  Tittle: "Nuestras Capacidades",
  Row1: {
    col1: {
      img: bgRopero,
      text: "Prendas: 250.000 mensuales.",
    },
    col2: {
      img: TelasMin,
      text: "Telas: 130 toneladas mensuales.",
    },
    col3: {
      img: MaqTejMin,
      text: "25 máquinas circulares de tejido de punto.",
    },
    col4: {
      img: CorteTelaMin,
      text: "Corte automatizado",
    },
  },
  Row2: {
    col1: {
      img: bgRopero,
      text: "Telas: 130 toneladas mensuales.",
    },
    col2: {
      img: MaqTejMin,
      text: "25 máquinas circulares de tejido de punto.",
    },
    col3: {
      img: HilosMin,
      text: "200 empresas aliadas para el proceso de manufactura.",
    },
    col4: {
      img: MaqTejMin2,
      text: "4.102 metros cuadrados de planta de producción.",
    },
  },
};

const section3DivTextil = {
  Img: MaqTej2,
  Tittle: "Algunos de nuestros clientes",
};

//CONTENIDO PARA EL PAQUETE COMPLETO

const HeaderPaqueteCompleto = {
  Banner: DisenoPC,
  Texto: "PAQUETE COMPLETO",
  Subtitulo: "",
  Descripcion:
    "En Disex te ayudamos a desarrollar tus ideas de marca, desde el entendimiento del ADN de marca, diseño, patronaje especializado, desarrollo de muestras, producción, revisión de calidad y logística a cualquier parte del mundo.",
  TextButton1: "Contáctanos",
  TextButton2: "DIVISIÓN TEXTIL",
  Navigate1: "/Contacto",
  Button1Classname: "button-transparent-general-center",
  Button2Classname: "button-null",
};

const ContSection1PaqCom = {
  Bg: BgPaqC,
};

const timelineData = [
  {
    Num: "1",
    Content: "Contacta con nosotros y cuéntanos acerca de tu marca.",
    Icon: ContactIcon,
  },
  {
    Num: "2",
    Content:
      "El área de Diseño y desarrollo se encarga de materializar tus ideas de colecciones, según el ADN de tu marca y tus requerimientos específicos.",
    Icon: DevelopmentIcon,
  },
  {
    Num: "3",
    Content:
      "Ajustamos y desarrollamos el patronaje y fits perfectos para tu marca.",
    Icon: PatronajeIcon,
  },
  {
    Num: "4",
    Content:
      "Nuestro taller de muestras se convierte en tu laboratorio, en el cual volvemos realidad cada diseño que harán parte de tus colecciones.",
    Icon: LabIcon,
  },
  {
    Num: "5",
    Content:
      "La etapa de producción comprende todo lo relacionado con corte tecnificado, confección en talleres fidelizados con DISEX, acabados premium y empaque.",
    Icon: ProcessIcon,
  },
  {
    Num: "6",
    Content:
      "Nuestra de área de calidad, supervisa y examina cuidadosamente cada una de las prendas en cada etapa del proceso productivo.",
    Icon: QualityIcon,
  },
  {
    Num: "7",
    Content:
      "El proceso de terminación comprende planchado, etiquetado, empaque y envío a cualquier parte del mundo. ",
    Icon: TruckIcon,
  },
];

const section2PaqCom = {
  Img: PoliAlgodon,
  Tittle: "Nuestras capacidades",
  Row1: {
    col1: {
      img: bgRopero,
      text: "Prendas: 250.000 mensuales.",
    },
    col2: {
      img: TelasMin,
      text: "Telas: 130 toneladas mensuales.",
    },
    col3: {
      img: CorteTelaMin,
      text: "Corte automatizado",
    },
    col4: {
      img: MaqTejMin2,
      text: "Alianzas con 30 talleres de confección que aportan 200 máquinas de coser.",
    },
  },
};

// CONTENIDO PARA ACERCA DE NOSOTROS
const HeaderAcercaNosotros = {
  Banner: Disexbg,
  Texto: "Acerca de Nosotros",
  Subtitulo: "¿Quienes Somos?",
  Descripcion:
    "Diseños Exclusivos es una empresa del sector textil / confección con más de 44 años de funcionamiento y verticalmente integrada, cuenta con 120 empleados directos y aproximadamente 450 indirectos; instalaciones de 4.100 metros cuadrados y orgullosamente Colombiana se ubicada en la ciudadde Medellín, cuenta con una capacidad productiva de 160.000 unidades mensuales y posibilidades de ampliación gracias a alianzas estratégicas.",
  TextButton1: "Contáctanos",
  Navigate1: "/Contacto",
  Button1Classname: "button-transparent-general-center",
  Button2Classname: "button-null",
};

const ContNosotrosSection1 = {
  Img: MaquinaTela,
  Tittle: "Somos DISEX",
  P1: "Somos DISEÑOS EXCLUSIVOS SAS, una empresa del sector textil – confección con más de 45 años de funcionamiento y verticalmente integrada.",
  P2: "Contamos con 112 empleados directos y aproximadamente 450 indirectos; instalaciones de 4.100 metros cuadrados y capacidad productiva de 160.000 unidades mensuales y posibilidades de ampliación gracias a alianzas estratégicas.",
  P3: "Somos una empresa orgullosamente colombiana, ubicada en la ciudad de Medellin, epicentro de la moda y el desarrollo textil del país.",
};

const ContNosotrosSection2 = {
  ImgBg: MaqTej2,
  Tittle: "Nuestra esencia",
  Img1: MaqTej,
  // P1: "",
  Img2: TelaRosa2,
  // P2: "",
  Img3: LogoDisex,
  // P3: "",
};

const ContNosotrosSection4 = {
  Fondo: TelaRayas,
};

//CONTENIDO PARA CONTACTO
const HeaderContacto = {
  Banner: MaqTej,
  Texto: "¡Gracias por tu interés en DISEX!",
  Subtitulo:
    "Nos enorgullece ser una empresa textil colombiana dedicada a ofrecer productos de alta calidad y un excelente servicio al cliente. Si tienes alguna pregunta, inquietud o comentario, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte.",
};
const IconSocialMedia = {
  Logo: LogoDisex,
  Follow: FollowIcon,
  LinkedinIcon: LinkedinIcon,
  InstagramIcon: InstagramIcon,
  LinkedinIconMin: LinkedinIconMin,
  InstagramIconMin: InstagramIconMin,
};

const contContactInfo = {
  Bg: MaqTej,
};
//Contenido para 404
const Cont404 = {
  Img: Mantenimiento,
  Title: "Página en Construcción",
  Text: "Estamos trabajando en la página, por favor regresa más tarde. Gracias.",
};

export default {
  ContHomeHeader,
  ContHomeSection1,
  ContHomeSection2,
  ContHomeSection3,
  ContHomeSection4,
  HeaderDivTextil,
  section1DivTextil,
  section2DivTextil,
  section3DivTextil,
  HeaderPaqueteCompleto,
  ContSection1PaqCom,
  timelineData,
  section2PaqCom,
  bgDivTextInfoContact,
  HeaderAcercaNosotros,
  ContNosotrosSection1,
  ContNosotrosSection2,
  ContNosotrosSection4,
  IconForms,
  HeaderContacto,
  IconSocialMedia,
  contContactInfo,
  Cont404,
};
