import React from "react";
import { Row, Col, NavLink } from "reactstrap";
import data from "../../assets/Scripts/scritps.jsx";
import FormsContact from "./FormsContact";
import ContainerBg from "../ContainerBg/ContainerBg.jsx";
import "./ContactInfo.css";
const { IconForms, IconSocialMedia, contContactInfo } = data;

export default function ContactInfo() {
  return (
    <section>
      <ContainerBg Bg={contContactInfo.Bg} hg={"100%"} hgMobile={"100%"}>
      <div className="filter1ContactInfo"></div>
        <Row className="RowNosotros">
          <Col xs={12} md={6} className="Col1section1InfoContact">
            <div className="espaciadosection1InfoContact">
              <Row>
                <Col sx={12} md={12}>
                  <Row>
                    <h2 className="tittlesection1InfoContact">
                      ¿Tienes alguna duda?
                    </h2>
                  </Row>
                  <Row>
                    <Col
                      sx={12}
                      md={12}
                      style={{ margi: "0px 0px", padding: "0px 0px" }}
                    >
                      <p className="subtittlesection1InfoContact">
                        Ponte en contacto con nosotros y cuentanos como podemos
                        ayudarte
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <p className="Parrafosection3Home">Datos de contacto:</p>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon1} alt="CallIcon" />
                </Col>
                <Col sx="6" md={10}>
                  <p className="Parrafosection3Home"> Telefono:</p>
                  <p className="Parrafosection3Home"> +57 (310) 407 2600</p>
                </Col>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon2} alt="CorreoIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home"> Correo: </p>
                  <p className="Parrafosection3Home">
                    <strong> comunicaciones@disex.com.co</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon3} alt="UbicacionIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home">Ubicación: </p>
                  <p className="Parrafosection3Home">
                    Cll 17 #43f - 23, Barrio Colombia, Medellín, Colombia
                  </p>
                </Col>
              </Row>
              <Row
                style={{ width: "100%", padding: "0px 0px", margin: "0px 0px" }}
              >
                <Col sx={2} md={4} className="socialMedia"></Col>
                <Col sx={6} md={6} className="Social-media1">
                  <NavLink href="https://www.Linkedin.com/Disex">
                    <img
                      className="LinkedinIcon"
                      src={IconSocialMedia.LinkedinIcon}
                      alt="LinkedinIcon"
                    />
                  </NavLink>
                </Col>
                <Col sx={6} md={6} className="Social-media2">
                  <NavLink href="https://www.instagram.com/disex.co/">
                    <img
                      className="InstagramIcon"
                      src={IconSocialMedia.InstagramIcon}
                      alt="InstagramIcon"
                    />
                  </NavLink>
                </Col>
              </Row>
            </div>
          </Col>

          {/* info Forms COLUMNA 2*/}
          <Col xs={12} md={6} className="Col2section1InfoContact">
            <div className="espaciado2section1InfoContact">
              <FormsContact />
            </div>
          </Col>
        </Row>
      </ContainerBg>
    </section>
  );
}
