import React, { useEffect, useState } from "react";
import "./TimeLine.css";
import data from "../../assets/Scripts/scritps";

export default function TimeLine() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const { timelineData } = data;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const items = document.querySelectorAll(".timeline li");

      items.forEach((item) => {
        if (isElementInViewport(item)) {
          if (scrollTop > lastScrollTop) {
            item.classList.add("in-view");
          } else {
            item.classList.remove("in-view");
          }
        }
      });

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("load", handleScroll);
    window.addEventListener("resize", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("load", handleScroll);
      window.removeEventListener("resize", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
      <div className="timeline">
        {/* <div> */}
          <ul>
            {timelineData.map((item, index) => (
              <li key={index}>
                <div>
                  <span>{item.Year}
                  <h2>{item.Num}</h2>
                  <p>{item.Content}</p>
                  <img src={item.Icon} alt="Icons" className="IconsTimeLine" />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        {/* </div> */}
      </div>
  );
}
