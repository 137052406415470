import React from "react";
import { Container } from "reactstrap";
import "./CardsInfo.css";

export default function CardsInfo({ contentFront, contentBack, icon }) {
  return (
    <Container className="ContainerCardInfo">
      <div className="cardInfo">
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <strong>{contentFront}</strong>
        <div className="card__body">Conocer más...</div>
        <span>{contentBack}</span>
      </div>
    </Container>
  );
}
