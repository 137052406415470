// NotFound.js
import React from "react";
import "./404NotFound.css";
import ButtonTransparent from "../../components/Buttons/ButtonTransparent/ButtonTransparent";
import { Container } from "reactstrap";
import Navigate from "../../components/Navigate/Navigate";
import data from "../../assets/Scripts/scritps.jsx";
const { Cont404 } = data;

export default function NotFound() {
  return (
    <>
      <Navigate />
      <Container fluid="true">
        <div className="NotFound">
          <img src={Cont404.Img} alt="Mantenimiento" className="NotFound-img" />
          <h1 className="NotFound-title">{Cont404.Title}</h1>
          <p className="NotFound-text">{Cont404.Text}</p>
          <ButtonTransparent
            className={"button-transparent-center-NotFound"}
            text={"Volver al inicio"}
            navigateto="/"
          />
        </div>
      </Container>
    </>
  );
}
