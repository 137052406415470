import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import data from "../../assets/Scripts/scritps.jsx";
const { IconSocialMedia } = data;

export default function Footer() {
  
  const navigate = useNavigate();

  const handleClick = (path) => () => {
    window.scrollTo(0, 0);
    navigate(path);
  }

  return (
    <footer className="footer">
      <Container fluid={true} className="container-footer">
        <Row>
          <Col xs={12} md={1} className="ColumnLogoFooter">
            <img
              src={IconSocialMedia.Logo}
              alt="Disex_Logo"
              className="footer-logo"
            />
          </Col>
          <Col xs={12} md={4} className="ColumnTextFooter" onClick={handleClick("/PTEELAFT")}>
            <p className="copyright" >
              
              &copy; {new Date().getFullYear()} Disex. Todos los derechos
              reservados.
            </p>
          </Col>
          <Col xs={12} md={2} className="ColumnTextFooter" onClick={handleClick("/PTEELAFT")}>
            <p className="privacy-policy" >POLITICAS PTEE/LAFT</p>
          </Col>
          <Col xs={12} md={3} className="ColumnTextFooter" onClick={handleClick("/Terminosycondiciones")}>
            <p className="terms-conditions">Términos y Condiciones</p>
          </Col>
          <Col xs={6} md={1} className="ColumnTextFooter">
            <img
              src={IconSocialMedia.LinkedinIconMin}
              className="social-icon"
              alt="Linkedin Icon"
            />
          </Col>
          <Col xs={6} md={1} className="ColumnTextFooter">
            <img
              src={IconSocialMedia.InstagramIconMin}
              className="social-icon"
              alt="Instagram Icon"
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
