import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./Home.jsx";
import MyNavbar from "./components/Navbar/Navbar.jsx";

// Importar Vistas
import Nosotros from "./Views/Nosotros/Nosotros.jsx";
import Contacto from "./Views/Contacto/Contacto.jsx";
// import Showroom from "./Views/Showroom/Showroom.jsx";
// import Clientes from "./Views/Clientes/Clientes.jsx";
// import CardsBlog from "./components/Cards/CardsBlog/CardsBlog.jsx";
import Chat from "./components/ChatIcon/Chat.jsx";
import DivisionT from "./Views/DivisionTextil/DivisionT.jsx";
import PaqueteC from "./Views/PaqueteCompleto/PaqueteC.jsx";
import PTEELAFT from "./Views/PTEE-LAFT/PTEE-LAFT.jsx";
import TermAndCond from "./Views/TermsAndCond/TermAndCond.jsx";
// import Blog from "./Views/Blog/Blog.jsx";
import NotFound from "./Views/404/404NotFound.jsx";

export default function App() {
  return (
    <Router>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/Clientes" element={<Clientes />} /> */}
        <Route path="/Nosotros" element={<Nosotros />} />
        {/* <Route path="/Showroom" element={<Showroom />} /> */}
        <Route path="/Contacto" element={<Contacto />} />
        <Route path="/DivisionTextil" element={<DivisionT />} />
        <Route path="/PaqueteCompleto" element={<PaqueteC />} />
        {/* <Route path="/PTEELAFT" element={<PTEELAFT />} /> */}
        <Route path="/Terminosycondiciones" element={<TermAndCond />} />
        {/* <Route path="/Blog" element={<Blog />} /> */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Chat />
      <Footer />
    </Router>
  );
}
