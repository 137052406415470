import React from "react";
import { Container } from "reactstrap";
import "./PaqueteC.css";
// import Preguntas from "../../components/Preguntas/Preguntas";
import Header from "../../components/Header/Header";
import TimeLine from "../../components/TimeLine/TimeLine";
// import CardsBlog from "../../components/Cards/CardsBlog/CardsBlog.jsx";
// import PaqCompleto from "../../assets/media/PC/DiseñoPC.jpg";
import data from "../../assets/Scripts/scritps";
import Navigate from "../../components/Navigate/Navigate";
import ContainerBg from "../../components/ContainerBg/ContainerBg";
import { Row, Col } from "reactstrap";
import ContactInfo from "../../components/Forms/ContactInfo";
const { HeaderPaqueteCompleto, ContSection1PaqCom, section2PaqCom } = data;

export default function PaqueteC() {
  function bgCol(bg) {
    const Fondo = bg;
    return {
      backgroundImage: `url(${Fondo})`,
    };
  }
  return (
    <>
      <section>
        <Navigate />
        <Header
          Banner={HeaderPaqueteCompleto.Banner}
          Texto={HeaderPaqueteCompleto.Texto}
          Subtitulo={HeaderPaqueteCompleto.Subtitulo}
          Descripcion={HeaderPaqueteCompleto.Descripcion}
          TextButton1={HeaderPaqueteCompleto.TextButton1}
          TextButton2={HeaderPaqueteCompleto.TextButton2}
          Button1Classname={HeaderPaqueteCompleto.Button1Classname}
          Button2Classname={HeaderPaqueteCompleto.Button2Classname}
          navigate1={HeaderPaqueteCompleto.Navigate1}
        />
      </section>
      {/* SECTION 1 */}
      <section>
        <ContainerBg Bg={ContSection1PaqCom.Bg} hg={"100%"} hgMobile={"100%"}>
          <Container fluid="true">
            <div className="filterSection1PC"></div>
            <h2 className="Tittlesection1PC">¿Cómo lo hacemos?</h2>
            <TimeLine />
          </Container>
        </ContainerBg>
      </section>
      {/* SECTION 2 */}
      <section>
        <ContainerBg Bg={section2PaqCom.Img} hg={"100vh"} hgMobile={"100vh"}>
        <div className="filterSection2PaqCom"></div>
          <Row className="TittleSection2PaqCom">
            <Col xs={12} md={12}>
              <h2>{section2PaqCom.Tittle}</h2>
            </Col>
          </Row>
          
          <Row className="Row1Section2PaqCom">
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2PaqCom.Row1.col1.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2PaqCom.Row1.col1.text}</h3>
              </div>
              
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2PaqCom.Row1.col2.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2PaqCom.Row1.col2.text}</h3>
              </div>
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2PaqCom.Row1.col3.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2PaqCom.Row1.col3.text}</h3>
              </div>
            </Col>
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2PaqCom.Row1.col4.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2PaqCom.Row1.col4.text}</h3>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </ContainerBg>
      </section>
      <section>
        <ContactInfo></ContactInfo>
      </section>
    </>
  );
}
