import React from "react";
import { Col, Container, Row } from "reactstrap";
import ButtonTransparent from "../Buttons/ButtonTransparent/ButtonTransparent.jsx";
import "./Header.css";

export default function Header({
  Banner,
  Texto,
  Subtitulo,
  Descripcion,
  TextButton1,
  TextButton2,
  Button1Classname,
  Button2Classname,
  navigate1,
  navigate2,
}) {
  return (
    <header>
      <Container fluid="true" className="media-container">
        <img src={Banner} alt="Background" className="background-image" />
        <div className="overlay">
          <div className="texto-central">
            <h1 className="header-title">{Texto}</h1>
            <h2 className="header-subtitle">{Subtitulo}</h2>
            <p className="header-description">{Descripcion}</p>
            <br />
            <Container className="buttonContainerHeader">
              <Row>
                <Col md={6} sx={12} className="Col1ButtonsHeader">
                  <ButtonTransparent
                    text={TextButton1}
                    navigateto={navigate1}
                    className={Button1Classname}
                  />
                </Col>
                <Col  md={6} sx={12} className="Col2ButtonsHeader">
                  <ButtonTransparent
                    text={TextButton2}
                    navigateto={navigate2}
                    className={Button2Classname}
                    
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Container>
    </header>
  );
}
