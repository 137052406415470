import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./DivisionT.css";
import Header from "../../components/Header/Header";
import DivTextildata from "../../assets/Scripts/scritps.jsx";
import CardsHover from "../../components/Cards/CardsHover/CardsHover.jsx";
import InfoContact from "../../components/Forms/ContactInfo.jsx";
import Navigate from "../../components/Navigate/Navigate.jsx";
import ContainerBg from "../../components/ContainerBg/ContainerBg.jsx";
const {
  HeaderDivTextil,
  bgDivTextInfoContact,
  section1DivTextil,
  section2DivTextil,
} = DivTextildata;

export default function DivisionT() {
  function bgCol(bg) {
    const Fondo = bg;
    return {
      backgroundImage: `url(${Fondo})`,
    };
  }
  return (
    <>
      <Navigate />
      <Header
        Banner={HeaderDivTextil.Banner}
        Texto={HeaderDivTextil.Texto}
        Subtitulo={HeaderDivTextil.Subtitulo}
        Descripcion={HeaderDivTextil.Descripcion}
        TextButton1={HeaderDivTextil.TextButton1}
        navigate1={HeaderDivTextil.Navigate1}
        Button1Classname={HeaderDivTextil.Button1Classname}
        Button2Classname={HeaderDivTextil.Button2Classname}
      />

      {/* SECTION 1 */}
      <section>
        <div className="bgDivisionTextil overflow-hidden">
          <div className="filterDivText"></div>
          <Container fluid={true}>
            <h2 className="TittleSection1DivisionT">
              Algunas de nuestras bases textiles
            </h2>
            <Row>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card1.TelaBg}
                  Tittle={section1DivTextil.Card1.NombreTela}
                  Content={section1DivTextil.Card1.Propiedades}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card2.TelaBg}
                  Tittle={section1DivTextil.Card2.NombreTela}
                  Content={section1DivTextil.Card2.Propiedades}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card3.TelaBg}
                  Tittle={section1DivTextil.Card3.NombreTela}
                  Content={section1DivTextil.Card3.Propiedades}
                />
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card4.TelaBg}
                  Tittle={section1DivTextil.Card4.NombreTela}
                  Content={section1DivTextil.Card4.Propiedades}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card5.TelaBg}
                  Tittle={section1DivTextil.Card5.NombreTela}
                  Content={section1DivTextil.Card5.Propiedades}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={section1DivTextil.Card6.TelaBg}
                  Tittle={section1DivTextil.Card6.NombreTela}
                  Content={section1DivTextil.Card6.Propiedades}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* SECTION2 */}
      
      <section>
        <ContainerBg Bg={section2DivTextil.Img} hg={"100vh"} hgMobile={"100vh"}>
        <div className="filterSection2PaqCom"></div>
          <Row className="TittleSection2PaqCom">
            <Col xs={12} md={12}>
              <h2>{section2DivTextil.Tittle}</h2>
            </Col>
          </Row>
          
          <Row className="Row1Section2PaqCom">
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2DivTextil.Row2.col1.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2DivTextil.Row2.col1.text}</h3>
              </div>
              
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2DivTextil.Row2.col2.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2DivTextil.Row2.col2.text}</h3>
              </div>
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2DivTextil.Row2.col3.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2DivTextil.Row2.col3.text}</h3>
              </div>
            </Col>
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(section2DivTextil.Row2.col4.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{section2DivTextil.Row2.col4.text}</h3>
              </div>
            </Col>
          </Row>
        </ContainerBg>
      </section>
      {/* SECTION 3 */}
      {/* ALGUNOS DE NUESTROS CLIENTES"!!! */}
      {/* <section>
        <ContainerBg Bg={section3DivTextil.Img} hg={"100vh"} hgMobile={"250vh"}>
          <div className="filter1DivTSection3"></div>
          <Row className="TittleSection3DivT">
            <Col xs={12} md={12}>
              <h2>{section3DivTextil.Tittle}</h2>
            </Col>
          </Row>
          <Row className="Row1Section3DivT">
            <Col xs={12} md={12} className="ColSection3DivT">
              <p>{section3DivTextil.P1}</p>
            </Col>
          </Row>

        </ContainerBg>
      </section> */}
      {/* SECTION 4 */}
      <section id="Forms">
        <InfoContact Fondo={bgDivTextInfoContact.Fondo} />
      </section>
    </>
  );
}
