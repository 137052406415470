import React from "react";
import "./Home.css";
import Header from "./components/Header/Header.jsx";
import homeData from "./assets/Scripts/scritps.jsx";
import ButtonTransparent from "./components/Buttons/ButtonTransparent/ButtonTransparent.jsx";
import { Container, Row, Col } from "reactstrap";
import ContactInfo from "./components/Forms/ContactInfo.jsx";
import CardsInfo from "./components/Cards/CardsInfo/CardsInfo.jsx";
//efecto skeleton
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ContainerBg from "./components/ContainerBg/ContainerBg.jsx";

const {
  ContHomeHeader,
  ContHomeSection1,
  ContHomeSection3,
  ContHomeSection2,
  ContHomeSection4,
} = homeData;

export default function Home() {
  return (
    <>
      <Header
        Banner={ContHomeHeader.Banner}
        Texto={ContHomeHeader.Texto}
        Subtitulo={ContHomeHeader.Subtitulo}
        TextButton1={ContHomeHeader.TextButton1}
        TextButton2={ContHomeHeader.TextButton2}
        Button1Classname={ContHomeHeader.Button1Classname}
        Button2Classname={ContHomeHeader.Button2Classname}
        navigate1={ContHomeHeader.Navigate1}
        navigate2={ContHomeHeader.Navigate2}
      />

      {/* SECCIÓN 1 */}
      <section>
        <ContainerBg
          Bg={ContHomeSection1.Bg}
          hg={"100vh"}
          hgMobile={"100vh"}
          className="bgimgsection1Home"
        >
          <div className="filter1">
            <Container fluid={true} className="ContainerSeccion1Home">
              <Row>
                <Col xs={12} md={12} className="ColPrendaHome">
                  <h2 className="tittle1seccion1Home">
                    {ContHomeSection1.Tittle}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="ColPrendaHome2">
                  <p className="Parrafoseccion1Home">
                    {ContHomeSection1.Parrafo}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="ColPrendaHome">
                  <ButtonTransparent
                    className={ContHomeSection1.Button1.classname}
                    text={ContHomeSection1.Button1.text}
                    navigateto={ContHomeSection1.Button1.navigateto}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ContainerBg>
      </section>

      {/* SECCIÓN 2 */}
      <section>
        <ContainerBg hg={"100vh"} hgMobile={"100vh"}>
          <div className="filter2"></div>
          <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
            <Col
              xs={12}
              md={6}
              style={{ padding: "0px 0px", margin: "0px 0px" }}
            >
              <ContainerBg
                Bg={ContHomeSection2.BgDivT}
                hg={"100%"}
                hgMobile={"50vh"}
              >
                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "50%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col1Divtextil">
                    <h2 className="tittle1section2Home">
                      División <br /> Textil
                    </h2>
                  </Col>
                </Row>

                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col2Divtextil">
                    <ButtonTransparent
                      text="Quiero más información"
                      navigateto="/DivisionTextil"
                      className={"button-transparent-section2Home-left"}
                    />
                  </Col>
                </Row>
              </ContainerBg>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ padding: "0px 0px", margin: "0px 0px" }}
            >
              <ContainerBg
                Bg={ContHomeSection2.BgPaqC}
                className="bgPaqCom"
                hg={"100%"}
                hgMobile={"50vh"}
              >
                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "50%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col1Divtextil">
                    <h2 className="tittle1section2Home">
                      Paquete <br /> Completo
                    </h2>
                  </Col>
                </Row>
                <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
                  <Col sx={12} md={12} className="Col2Divtextil">
                    <ButtonTransparent
                      text="Quiero más información"
                      navigateto="/PaqueteCompleto"
                      className={"button-transparent-section2Home-left"}
                    />
                  </Col>
                </Row>
              </ContainerBg>
            </Col>
          </Row>
        </ContainerBg>
      </section>

      {/* section 3 */}
      <section>
        <ContainerBg Bg={ContHomeSection3.Bg} hg={"100%"} hgMobile={"100%"}>
          <div className="filter3"></div>
            <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
              <Col sx={12} md={12}>
                <Row>
                  <Col sx={12} md={12}>
                    <h2 className="tittle1section3Home">
                      {ContHomeSection3.Tittle}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col sx={12} md={12}>
                    <p className="subtitle1section3Home">
                      {ContHomeSection3.Parrafo}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            
            <Row className="RowCardsInfoHome">
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card1.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card1.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card1.icon}
                />
              </Col>
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card2.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card2.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card2.icon}
                />
              </Col>
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card3.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card3.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card3.icon}
                />
              </Col>
            </Row>
            <Row className="RowCardsInfoHome">
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card4.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card4.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card4.icon}
                />
              </Col>
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card5.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card5.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card5.icon}
                />
              </Col>
              <Col sx={12} md={4} className="ColCardsInfoHome">
                <CardsInfo
                  contentFront={
                    ContHomeSection3.ContInfoCards.Card6.contentFront
                  }
                  contentBack={ContHomeSection3.ContInfoCards.Card6.contentBack}
                  icon={ContHomeSection3.ContInfoCards.Card6.icon}
                />
              </Col>
            </Row>
          
        </ContainerBg>
      </section>

      {/* SECCIÓN 4 */}
      <section>
        <ContactInfo Fondo={ContHomeSection4.Fondo} />
      </section>
    </>
  );
}
