import React from "react";
import { Container } from "reactstrap";
import "./Contacto.css";
import Header from "../../components/Header/Header";
import Navigate from "../../components/Navigate/Navigate";
import data from "../../assets/Scripts/scritps.jsx";
import ContactInfo from "../../components/Forms/ContactInfo.jsx";
const { HeaderContacto } = data;

export default function Contacto() {
  return (
    <>
      <Navigate />
      <section>
        <Header
          Banner={HeaderContacto.Banner}
          Texto={HeaderContacto.Texto}
          Subtitulo={HeaderContacto.Subtitulo}
        ></Header>
      </section>
      {/* SECTION 1 */}
      <section>
        {/* <Container fluid="true" className="ContainerContac">
          <div className="bgimgsection1Contact">
            <div className="filter2">
              
            </div>
          </div>
        </Container> */}
        <ContactInfo />
      </section>

      <section>
        <Container fluid style={{ textAlign: "center" }}>
          <br />
          <h2 className="TituloPrincipal">Nuestra Sede</h2>
          <br />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.345711734312!2d-75.5747814252164!3d6.218059826637877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4428329a4e4b4f%3A0x10ebed49afbb99fc!2sDISEX%20Dise%C3%B1os%20Exclusivos%20S.A.S!5e0!3m2!1ses!2sco!4v1713530728978!5m2!1ses!2sco"
            width="100%"
            height="700px"
            style={{ border: 0 }}
            allowfullscreen="true"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Container>
      </section>
    </>
  );
}
