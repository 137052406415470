import React from "react";
import "./ContainerBg.css";
import { Container } from "reactstrap";

export default function ContainerBg({ Bg, hg, hgMobile, ...props }) {
  return (
    <Container fluid={true} style={bgimg(Bg, hg, hgMobile)}>
      {props.children}
    </Container>
  );
}

function bgimg(bg, hg, hgMobile) {
  const Fondo = bg;
  const height = hg;
  const isMobile = window.innerWidth <= 1024;
  const hgM = hgMobile;
  return {
    backgroundImage: `url(${Fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "100% !important",
    height: isMobile ? hgM : height,
    objectFit: "cover",
    backgroundAttachment: "fixed",
    overflow: "hidden !important",
    padding: "0px 0px",
    margin: "0px 0px",
  };
}
