import React from "react";
import "./ButtonTransparent.css";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
export default function ButtonTransparent({ text, navigateto, className }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateto);
  };
  return (
    <Button className={className} onClick={handleClick}>
      {text}
    </Button>
  );
}
