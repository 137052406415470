import React from "react";
import "./CardsHover.css";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";


export default function CardsHover({ Img, Tittle}) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Contacto");
  };
  return (
    <Container className="columnas-figure" onClick={handleClick}>
      <div className="figure">
        <img className="img-card-hover" src={Img} alt="ImgCardHover" />
        <div className="capa-texto-card-hover">
          <h2 className="title-card-hover">{Tittle}</h2>
          <h4 className="text-card-hover">QUIERO MÁS INFORMACIÓN...</h4>
          {/* <ul className="list-card-hover">
            {Object.keys(Content).map((key, index) => (
              <Row key={index}>
                <strong>{key}:</strong>
                <p>{Content[key]}</p>
              </Row>
            ))}
          </ul> */}
        </div>
      </div>
    </Container>
    
  );
}
