import axios from "axios";

const api = axios.create({
  baseURL: "https://apidika2.okassia.com",
});

//Api for send email
export const sendEmail = async (data) => {
  try {
    const response = await api.post("/send_email_global", data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

